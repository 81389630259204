/* eslint-disable consistent-return */

/**
 * Используется для обновления лимита лицензий
 * Вызывается после успешной активации лицензий с помощью функций
 * systemService.activateLicenseText и systemService.uploadLicenceFile
 * Подробнее тут https://wimarksystems.atlassian.net/browse/WNE-3777?focusedCommentId=33100
 *
 */

import { AXIOS_INSTANCE } from '@/api';
import { API_URL } from '@/config';
import store from '@/store';

const POST_UPDATE_LICENSE_URL = `${API_URL}/api/license`;

/**
 * Функция-фетчер для отправки запроса на обновление лицезии
 *
 * @param {Object} settings - Настройки запроса.
 * @param {string} settings.licenseText - Текст лицензии
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const postUpdateLicenseFetcher = async (settings) => {
  const { licenseText } = settings;

  if (!licenseText) {
    throw new Error('License text must be set');
  }

  const updateQuery = {
    action: 'U',
    items: { license: { token: licenseText } }
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_UPDATE_LICENSE_URL, updateQuery);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера postUpdateLicenseFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {string} settings.licenseText - Текст лицензии
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const updateLicense = async (settings, options) => {
  const {
    onLoading, onSuccess, onError, successMessage
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }
    store.commit('setLoading');

    const response = await postUpdateLicenseFetcher(settings);
    const { status, description: errorDescription } = response;

    if (status === 'error') {
      throw new Error(errorDescription);
    }

    if (status === 'success' && onSuccess) {
      onSuccess();
    }

    if (status === 'success') {
      store.commit('setSuccess', successMessage);
      return Promise.resolve({ status });
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default updateLicense;
