<template>
  <div class="w-100">
    <div class="w-100" style="position: relative">
      <loader-spinner-small v-if="showSpinnerFromProp" centered></loader-spinner-small>
      <div class="card-block p-0">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row flex-wrap justify-content-between">
            <div class="d-flex  flex-row align-items-top mr-2 mb-q  input-wrapper-in-system-activate-text-licence">
              <div class="form-group mb-0 w-100">
                    <input
                      type="text"
                      :placeholder="$t('system.addCode')"
                      class="form-control w-100"
                      v-model="licenseCode"
                    />
              </div>
            </div>
            <transition name="SubmitButtonTransitionInSystemActivateTextLicence" mode="out-in">
              <div clas="mb-q">
                <WButton success md
                  @click="submitButtonHandler"
                  :disabled="submitButtonDisabled"
                >
                  {{ $t('system.uploadFileButton') }}
                </WButton>
              </div>
            </transition>
          </div>

          <div>
            <div class="d-flex justify-content-start">
              <div>
                <a href="#" class="ml-q" @click.prevent="clearLicenseCode">
                  <span>{{ $t('general.clear') }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import systemService from "../../services/systemService";
import licensesApi from './api';

export default {
  name: 'SystemActivateTextLicence',
  components: {},
  data() {
    return {
      licenseCode: '',
    };
  },
  computed: {
    submitButtonDisabled() {
      return !this.licenseCodeIsNotEmpty || this.isTextLicenceActivateRequestExecuting
    },
    isTextLicenceActivateRequestExecuting(){
      return this.$store.state.licenceTextActivateRequestExecuting
    },
    licenseCodeIsNotEmpty(){
      if(this.licenseCode !== ''){
        return true
      }
      return false
    },
  },
  props: {
    showSpinnerFromProp: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  methods: {
    clearLicenseCode(){
      this.licenseCode = '';
    },
    async successActivateHandler() {
      await licensesApi.updateLicense({
        licenseText: this.licenseCode
      }, {
        onSuccess: () => {
          this.clearLicenseCode();
          this.$emit('success');
        },
        successMessage: this.$t('licenses.notificationLicenseSuccessfullyUpdated')
      });
    },
    submitButtonHandler(){
      systemService.activateLicenseText(this, this.licenseCode)
      // console.log('submit')
    }
  }
};
</script>

<style scoped>
  .input-wrapper-in-system-activate-text-licence {
    min-width: 250px !important;
    width: 73% !important;
  }
</style>

<style>
.SubmitButtonTransitionInSystemActivateTextLicence-enter-active,
.SubmitButtonTransitionInSystemActivateTextLicence-leave-active {
  transition: opacity 0.5s;
}

.SubmitButtonTransitionInSystemActivateTextLicence-enter,
.SubmitButtonTransitionInSystemActivateTextLicence-leave-to {
  opacity: 0;
}
</style>
