import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';

export default {
  // getLicenseDailyLog(ctx) {
  //   ctx.$store.commit('enableLoadingSummary');
  //
  //   const query = {
  //     action: 'R',
  //     fields: ['cpe_connected', 'radar_connected', 'heatmap', 'create_at', 'id'],
  //     timebounds: {
  //       start: moment(ctx.drPickerDate.startDate).unix(),
  //       stop: moment(ctx.drPickerDate.endDate).unix()
  //     }
  //   };
  //   // NOTE: обнуляемся
  //   ctx.connectedChart.series = [];
  //
  //   Vue.axios.post(`${API_URL || ''}/api/license_log_daily`, query).then(
  //     (response) => {
  //       ctx.$store.commit('disableLoadingSummary');
  //       if (response.data.status === 'success') {
  //         const { data } = response.data;
  //         const fullDataObject = {
  //           cpes: { name: ctx.$t('licenses.types.cpes'), type: 'column', data: [] },
  //           radar: { name: ctx.$t('licenses.types.radar'), type: 'column', data: [] },
  //           heatmap: { name: ctx.$t('licenses.types.heatmap'), type: 'column', data: [] }
  //         };
  //         // тут будем сохранять средние значения за выбраный период
  //         const averageValues = {
  //           daysInSelectedPeriod: 0,
  //           cpeAll: 0,
  //           radarAll: 0,
  //           hetmapAll: 0,
  //           cpeAvg: 0,
  //           radarAvg: 0,
  //           heatmapAvg: 0
  //         };
  //         // записывваем количество периодов
  //         averageValues.daysInSelectedPeriod = data.itemslist.length;
  //         const xAxis = data.itemslist
  //           .sort((a, b) => a.create_at - b.create_at)
  //           .map((el) => {
  //             // console.log(el)
  //             fullDataObject.cpes.data.push(el.cpe_connected);
  //             fullDataObject.radar.data.push(el.radar_connected);
  //             fullDataObject.heatmap.data.push(el.heatmap);
  //             // увеличиваем счетчики для дальейшего подсчета средих зачеий
  //             averageValues.cpeAll += el.cpe_connected;
  //             averageValues.radarAll += el.radar_connected;
  //             averageValues.hetmapAll += el.heatmap;
  //             return moment(el.create_at * 1000).format('DD.MM.YYYY');
  //           });
  //         // считаем средние значения
  //         averageValues.cpeAvg = this.calcAvg(averageValues.cpeAll, averageValues.daysInSelectedPeriod);
  //         averageValues.radarAvg = this.calcAvg(averageValues.radarAll, averageValues.daysInSelectedPeriod);
  //         averageValues.heatmapAvg = this.calcAvg(averageValues.hetmapAll, averageValues.daysInSelectedPeriod);
  //         // console.log(averageValues)
  //         // пробрасывам подсчитаные значения в компонент
  //         ctx.averageValues = averageValues;
  //         // console.log(xAxis)
  //         ctx.connectedChart.xAxis.categories = xAxis;
  //         ctx.connectedChart.series = [fullDataObject.cpes, fullDataObject.radar, fullDataObject.heatmap];
  //         // console.log(data);
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('disableLoadingSummary');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('disableLoadingSummary');
  //     }
  //   );
  // },
  getLicenseDailyLog(ctx, useLocationFilter = false) {
    const isUseLocationFilters = useLocationFilter
    ctx.$store.commit('enableLoadingSummary');

    const query = {
      action: 'R',
      fields: ['cpe_connected', 'radar_connected', 'heatmap', 'create_at', 'id'],
      timebounds: {
        start: moment(ctx.drPickerDate.startDate).unix(),
        stop: moment(ctx.drPickerDate.endDate).unix()
      }
    };
    if (!isUseLocationFilters) {
      query.location = ctx.$store.getters.getDefaultLocationForRequests(ctx);
      query.with_childs = ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    }

    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }

    // NOTE: обнуляемся
    ctx.connectedChart.series = [];

    Vue.axios.post(`${API_URL || ''}/api/license_log_daily`, query).then(
      (response) => {
        ctx.$store.commit('disableLoadingSummary');
        if (response.data.status === 'success') {
          const { data } = response.data;
          const fullDataObject = {
            cpes: { name: ctx.$t('licenses.types.cpes'), type: 'column', data: [] },
            radar: { name: ctx.$t('licenses.types.radar'), type: 'column', data: [] },
            heatmap: { name: ctx.$t('licenses.types.heatmap'), type: 'column', data: [] }
          };
          // тут будем сохранять средние значения за выбраный период
          const averageValues = {
            daysInSelectedPeriod: 0,
            cpeAll: 0,
            radarAll: 0,
            hetmapAll: 0,
            cpeAvg: 0,
            radarAvg: 0,
            heatmapAvg: 0
          };
          // записывваем количество периодов
          averageValues.daysInSelectedPeriod = data.itemslist.length;
          const xAxis = data.itemslist
            .sort((a, b) => a.create_at - b.create_at)
            .map((el) => {
              // console.log(el)
              fullDataObject.cpes.data.push(el.cpe_connected);
              fullDataObject.radar.data.push(el.radar_connected);
              fullDataObject.heatmap.data.push(el.heatmap);
              // увеличиваем счетчики для дальейшего подсчета средих зачеий
              averageValues.cpeAll += el.cpe_connected;
              averageValues.radarAll += el.radar_connected;
              averageValues.hetmapAll += el.heatmap;
              return moment(el.create_at * 1000).format('DD.MM.YYYY');
            });
          // считаем средние значения
          averageValues.cpeAvg = this.calcAvg(averageValues.cpeAll, averageValues.daysInSelectedPeriod);
          averageValues.radarAvg = this.calcAvg(averageValues.radarAll, averageValues.daysInSelectedPeriod);
          averageValues.heatmapAvg = this.calcAvg(averageValues.hetmapAll, averageValues.daysInSelectedPeriod);
          // console.log(averageValues)
          // пробрасывам подсчитаные значения в компонент
          ctx.averageValues = averageValues;
          // console.log(xAxis)
          ctx.connectedChart.xAxis.categories = xAxis;
          ctx.connectedChart.series = [fullDataObject.cpes, fullDataObject.radar, fullDataObject.heatmap];
          // console.log(data);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('disableLoadingSummary');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('disableLoadingSummary');
      }
    );
  },


  calcAvg(counter, periods) {
    try {
      if (isNaN(Math.round(counter / periods))) {
        return undefined;
      }
      return Math.round(counter / periods);
    } catch (e) {
      return undefined;
    }
  }
};
