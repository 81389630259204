/**
 * API для работы с лицензиями
 * @module licensesApi
*/

import updateLicense from './updateLicense';

const licensesApi = {
  updateLicense
};

export default licensesApi;
