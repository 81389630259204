<template>
  <div>
    <div v-if="isAdStatsShowing === true">
      <license-stats
        :dateStart="dateStartForStatistic"
        :licenseName="licenseNameForViewsStatistic"
        :licenseId="licenseIdForViewsStatistic"
        @closeLicensesStats="closeLicensesStats"
      />
    </div>
    <div v-else class="row">
      <div class="col-lg-12 pl-1 pr-1">
        <div v-show="false"  class="row pl-1">
          <div class="filters__multiple">
            <div class="input-group input-group-sm filters__input">
                  <span class="input-group-addon filters__field-icon"><i
                    class="fa fa-map-marker"></i></span>
              <select @change="refreshLicense"
                      class="form-control form-control-sm filters__field"
                      v-model="filters.location">
                <option :selected="true" :value="'All locations'">{{
                  $t('general.allLocations')
                  }}
                </option>
                <option v-for="location in locationsList" :value="location.id"
                        :key="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">
            <div
              class="  d-flex align-items-end justify-content-start"
            >
              <Switch-component
                :disabled="this.filters.location === 'All locations'"
                v-model="filters.with_childs"
                class="switcher-with-childs-for-use-in-filters"
                @change="refreshLicense"
              />
            </div>
            <div
              class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">
              <small class="with-child">{{ $t('general.withChild') }}</small>
            </div>
          </div>
        </div>


        <div v-if="isRootAdmin" class="row mb-1">
          <WButton primary
            customClass="mr-1 ml-auto"
            v-if="showGoToLicencesStatiscticButton"
            @click="openLicensesStats()"
            :disabled="disablingGoToLicencesStatiscticButton"
          >
            {{ $t('licenses.licensesStats') }}
          </WButton>
        </div>
        <div class="mt-2 mb-2" style="position: relative">
        <div class="row licenses-information-wrapper-block d-flex flex-row justify-content-around flex-wrap">
          <div v-if="hasNMS" class="col-lg-4 col-md-4 col-sm-4">
            <div class="card widget">
              <loader-spinner-small v-if="isSpinnerShowingForLicencesInfoCards" ></loader-spinner-small>
              <!--<div class="card-header">-->
              <!--<i class="fa fa-align-justify"></i> CPEs limits-->
              <!--</div>-->
              <div class="card-block p-0">
                <!--<div class="card-header">License</div>-->

                <div class="social-box mb-1">
                  <i class="bg-info">
<!--                    <img src="/static/img/router_white.svg" alt="" class="img img-responsive widget-img" />-->
                    <i style="" class= "bg-info glyphicons glyphicons-router "></i>
                  </i>

                  <ul v-if="licenseItems.license">
                    <li>
                      <strong v-if="licenseItems.license.current || licenseItems.license.current === 0">{{ licenseItems.license.current }}</strong>
                      <strong v-else>—</strong>
                      <span>{{ $t('system.current') }}</span>
                    </li>
                    <li>
                      <strong v-if = "licenseItems.license.limit || licenseItems.license.limit === 0">{{ licenseItems.license.limit }}</strong>
                      <strong v-else>—</strong>
                      <span>{{ $t('system.limit') }}</span>
                    </li>
                  </ul>
                </div>

                <!--<div class="card-widget">-->
                <!--<i class="bg-info"><img src="/static/img/router_white.svg" alt=""-->
                <!--class="img img-responsive widget-img"></i>-->
                <!--<div class="widget-text">-->
                <!--<div class="h5 text-primary" v-if="licenseItems.license">-->
                <!--{{licenseItems.license.limit}}-->
                <!--</div>-->
                <!--<div class="text-muted text-uppercase font-weight-bold font-xs">-->
                <!--Current CPEs limits-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->

<!--                <div v-if="isRootAdmin" class="card-footer">-->
<!--                  <div v-if="!licenseInput">-->
<!--                    <a @click="licenseInput = true" class="font-xs btn-block text-muted">-->
<!--                      {{ $t('system.addLicense') }}-->
<!--                      <i class="fa fa-angle-right float-right font-lg" />-->
<!--                    </a>-->
<!--                  </div>-->

<!--                  <div v-if="licenseInput" class="input-group">-->
<!--                    <input-->
<!--                      type="email"-->
<!--                      :placeholder="$t('system.addCode')"-->
<!--                      class="form-control"-->
<!--                      v-model="licenseCode"-->
<!--                    />-->
<!--                    <div class="input-group-btn">-->
<!--                      <button type="button" class="btn btn-info mb-0" @click="sendLicenseCode">-->
<!--                        {{ $t('general.submit') }}-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <div v-if="hasPortal" class="col-lg-4 col-md-4 col-sm-4">
            <div class="card widget">
              <loader-spinner-small v-if="isSpinnerShowingForLicencesInfoCards" ></loader-spinner-small>
              <!--<div class="card-header">-->
              <!--<i class="fa fa-align-justify"></i> CPEs limits-->
              <!--</div>-->
              <div class="card-block p-0">
                <!--<div class="card-header">License</div>-->

                <div class="social-box mb-1">
                  <i class="bg-info">
                    <i style="" class= "bg-info glyphicons  glyphicons-display "></i>
                  </i>

                  <ul v-if="licenseItems.license">
                    <li>
                      <strong v-if="licenseItems.license.current_portal || licenseItems.license.current_portal === 0">{{ licenseItems.license.current_portal }}</strong>
                      <strong v-else>—</strong>
                      <span>{{ $t('system.portalCurrent') }}</span>
                    </li>
                    <li>
                      <strong v-if="licenseItems.license.portal || licenseItems.license.portal === 0">{{ licenseItems.license.portal }}</strong>
                      <strong v-else>—</strong>
                      <span>{{ $t('system.portalLimit') }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isContentAnalyticsEnabled" class="col-lg-4 col-md-4 col-sm-4" >
            <div class="card widget">
              <loader-spinner-small v-if="isSpinnerShowingForLicencesInfoCards" ></loader-spinner-small>
              <!--<div class="card-header">-->
              <!--<i class="fa fa-align-justify"></i> CPEs limits-->
              <!--</div>-->
              <div class="card-block p-0">
                <!--<div class="card-header">License</div>-->

                <div class="social-box mb-1">
                  <i class="bg-info">
                    <i style="" class= "bg-info glyphicons glyphicons-stats "></i>
                  </i>

                  <ul v-if="licenseItems.license">
                    <li>
                      <strong v-if="licenseItems.license.current_analytics || licenseItems.license.current_analytics === 0">{{ licenseItems.license.current_analytics}}</strong>
                      <strong v-else>—</strong>
                      <span>{{ $t('system.analyticsCurrent') }}</span>
                      <InfoIconBasedOnSpan class="info-in-licences-card-in-system" :content="$t('system.analyticsHint')"/>
                    </li>
                    <li>
                      <strong v-if="licenseItems.license.analytics || licenseItems.license.analytics === 0">{{ licenseItems.license.analytics }}</strong>
                      <strong v-else>—</strong>
                      <span>{{ $t('system.analyticsLimit') }}</span>
                      <InfoIconBasedOnSpan class="info-in-licences-card-in-system" :content="$t('system.analyticsHint')"/>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
        </div>

          <div class="row licenses-information-wrapper-block">
            <div v-if="isRootAdmin" class="col-lg-12 ">
              <div class=" d-flex flex-column justify-content-start align-items-start p-2">

<!--                <div class="h5">{{$t('system.licenceActivationCaption')}}</div>-->
<!--                <div class="h6 mt-1">{{$t('system.byText')}}</div>-->
<!--                <SystemActivateTextLicence @success="handleSuccessActivateTextLicence" class=""-->
<!--                                           v-if="isRootAdmin"></SystemActivateTextLicence>-->
<!--                <div class="h6 mt-1">{{$t('system.byFile')}}</div>-->
<!--                <SystemUploadLicenceFile @success="handleSuccessUploadLicenceFile" class=""-->
<!--                                         v-if="isRootAdmin"></SystemUploadLicenceFile>-->
                <div class="d-flex justify-content-center w-100 flex-wrap mb-2">
                  <div class="h5">{{$t('system.licenceActivationCaption')}}</div>
                </div>
                <div class="w-100 d-flex flex-row justify-content-around flex-wrap">
                  <div class="card col-lg-4 licence-activate-blocks p-2 d-flex flex-column align-items-center">
                    <loader-spinner-small v-if="isSpinnerShowingForActivateLicencesCards" centered></loader-spinner-small>
                    <div class="h6 mt-1">{{$t('system.byText')}}</div>
                    <SystemActivateTextLicence   @success="handleSuccessActivateTextLicence" class=""
                                               v-if="isRootAdmin"></SystemActivateTextLicence>
                  </div>
                  <div class="card col-lg-4 licence-activate-blocks p-2 d-flex flex-column align-items-center">
                    <loader-spinner-small v-if="isSpinnerShowingForActivateLicencesCards" centered></loader-spinner-small>
                    <div class="h6 mt-1">{{$t('system.byFile')}}</div>
                    <SystemUploadLicenceFile  @success="handleSuccessUploadLicenceFile" class=""
                                             v-if="isRootAdmin"></SystemUploadLicenceFile>
                  </div>

                </div>
              </div>


            </div>
          </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import systemService from '../services/systemService';
import commonService from '../services/commonService';
import LicenseStats from '../components/System/license-stats.vue';
import cpeService from '../services/cpeService';
import SwitchComponent from '../components/Universal/Switch-component.vue';
import SystemUploadLicenceFile from '../components/System/SystemUploadLicenceFile.vue';
import SystemActivateTextLicence from '../components/System/SystemActivateTextLicence.vue';
import InfoIconBasedOnSpan from '../components/Universal/info-icon-based-on-span.vue';

const { mapState: WLCProductMapState } = createNamespacedHelpers('WLCProduct');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'System',
  components: { LicenseStats, SwitchComponent, SystemUploadLicenceFile, SystemActivateTextLicence, InfoIconBasedOnSpan },
  data() {
    return {
      dateStartForStatistic: null,
      isAdStatsShowing: false,
      licenseInput: false,
      licenseCode: '',
      filters: {
        location: 'All locations',
        with_childs: true,

      }
    };
  },
  computed: {
    ...WLCProductMapState({
      isWLCProduct: state => state.isWLCProduct
    }),
    ...featureFlagsMapGetters(['isContentPortalEnabled', 'isPortalOnlyModeEnabled', 'isContentAnalyticsEnabled']),
    hasPortal() {
      if (this.isPortalOnlyModeEnabled || this.isContentPortalEnabled) {
        return true;
      }
      return false;
    },
    hasNMS() {
      if (!this.isPortalOnlyModeEnabled) {
        return true;
      }
      return false;
    },
    isLicencesInfoGetRequestExecuting(){
      return this.$store.state.licencesInfoGetRequestExecuting;
    },
    isSpinnerShowingForLicencesInfoCards(){
      return this.isLicencesInfoGetRequestExecuting;
    },
    isSpinnerShowingForActivateLicencesCards(){
      return this.islicenceFileUploadingRequestExecuting || this.isTextLicenceActivateRequestExecuting

    },
    showGoToLicencesStatiscticButton() {
      // показывать кнопку "Статистика подключенных лицензий во всех сборках кроме WLC"
      return !this.isWLCProduct;
    },
    disablingGoToLicencesStatiscticButton(){
      return this.islicenceFileUploadingRequestExecuting || this.isTextLicenceActivateRequestExecuting
    },
    isTextLicenceActivateRequestExecuting(){
      return this.$store.state.licenceTextActivateRequestExecuting
    },
    islicenceFileUploadingRequestExecuting(){
      return this.$store.state.licenceFileUploadingRequestExecuting
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    locationsList() {
      return this.$store.getters.locationsListSorted;
    },
    licenseItems() {
      return this.$store.state.license;
    },
    isRootAdmin() {
      return this.$store.getters.isRootAdmin;
    }
  },
  // beforeDestroy() {
  //   const componentName = this.$options.name;
  //   this.$store.commit('cancelAllRequestsAndClearAllTokensForComponent', {componentName: componentName})
  // },
  methods: {
    handleSuccessUploadLicenceFile() {
      this.refreshLicense();
    },
    handleSuccessActivateTextLicence() {
      this.refreshLicense();
    },
    refreshLicense(){
      systemService.getLicense(this, true);
    },
    getLicense() {
      // systemService.getLicense(this);
      systemService.getLicense(this, true);
    },
    sendLicenseCode() {
      systemService.sendLicenseCode(this);
    },
    openLicensesStats(licenseId, licenseName) {
      if (!licenseId) {
        this.licenseIdForViewsStatistic = '';
      } else {
        this.licenseIdForViewsStatistic = licenseId;
      }
      if (!licenseName) {
        this.licenseNameForViewsStatistic = '';
      } else {
        this.licenseNameForViewsStatistic = licenseName;
      }
      this.isAdStatsShowing = true;
    },
    closeLicensesStats() {
      this.isAdStatsShowing = false;
    }
  },
  // created() {
  //   systemService.getLicense(this);
  //   this.$store.commit('setCpesList', false);
  //   cpeService.getAllCpes(this);
  // },

  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }


    // systemService.getLicense(this);
    systemService.getLicense(this, true);
    this.$store.commit('setCpesList', false);
    cpeService.getAllCpes(this);
  },

  mounted() {
    if (this.$route.params.licenseId && this.$route.params.licenseName) {
      this.openLicensesStats(this.$route.params.licenseId, this.$route.params.licenseName);
    }
    if (this.$route.params.start) {
      this.dateStartForStatistic = this.$route.params.start;
      this.isAdStatsShowing = true;
    }
    if (this.$route.params.licenseStatsShowing) {
      this.isAdStatsShowing = true;
    }
  }
};
</script>

<style lang="scss">
  .info-in-licences-card-in-system {
    margin-left: 0.25em !important;
    font-size: 0.8em !important;
  }
.card.widget {
  min-width: 150px;
}
.licence-activate-blocks {
  min-width: 300px !important;
}
.card-widget {
  display: flex;
  align-content: center;
  align-items: center;
  min-height: 70px;
  margin: 1rem;
}

.widget-img {
  max-width: 100%;
  width: 60px;
  padding: 10px;
}

.widget-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin-left: 10px;
}

.widget-text div {
  height: 100%;
}

.widget-text .h5 {
  margin-bottom: 5px;
}
</style>
